body {
  
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 14px;
  
}


strong {
  font-family: 'Roboto-Bold', sans-serif;
}


h1, h2, h3, h4, h5 {
  
  color: #5a2f23;
  font-family: 'rex-bold';
  
}
h1 a, h2 a, h3 a, h4 a, h5 a {
  
  color: #5a2f23;
  text-decoration: none;
  
}


a {
  text-decoration: none !important;
}

a.show-popup {
  display: inline-block;
  border: 2px solid #5a2f23;
  border-radius: 0;
  color: #5a2f23;
  margin-top: 15px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 1rem;
}
a.show-popup:hover,
a.show-popup:focus,{
  outline: none;
}

.pum-theme-119 .pum-container, .pum-theme-hello-box .pum-container {
  border: 14px solid #5a2f23 !important;
}


/* ------ #top-menu ------ */


#top-menu {
  
  position: relative;
  z-index: 999;
  width: 100%;
  height: 180px;
  background: url('/app/themes/mytheme/dist/images/top-menu-bg.png') no-repeat top center;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: table;
    width: 100%;
  
    li {
      
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      position: relative;
      width: 30%;
      
      a {
        
        display: block;
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        
      }
      
      a:before {
      
        content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        background: url('/app/themes/mytheme/dist/images/top-menu-marker.png') no-repeat top center;
        left: 0;
        top: 26px;
        
      }
    }
  }  
}


/* ------ header.banner ------ */


header.home {
  
  position: relative;
  top: -120px;
  margin-bottom: -120px;
  
}


/* ------ nav.navbar ------ */


nav.navbar {
  
  background-color: #5a2f23;
  
  button {
    background: #5a2f23;
  }
  
  a.nav-link {
    display: block;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
  }
  
}


/* ------ header.mobile ------ */


header.mobile {
  
  padding: 80px 0 40px 0;
  background: #5a2f23 url('/app/themes/mytheme/dist/images/bg.jpg');
  
  img {
    display: block;
    margin: 0 auto;
  }
  
}


/* ------ #exc ------ */


#exc {
  
  position: relative;
  top: -50px;
  width: 100%;
  height: 290px;
  padding-top: 90px;
  background: url('/app/themes/mytheme/dist/images/excellences-bg.png') no-repeat top center;
  
}


/* ------ content ------ */


.content main {

  h1 {
    padding: 20px 0 0 0;
    margin-bottom: 25px;
  }

  .yur-cons-block {
    
    position: relative;
    width: 100%;
    min-height: 200px;
    padding: 10px 20px 10px 10px;
    background: #fafafa;
    
    a {
      position: absolute;
      top: 10px;
      right: 10px;
      margin-top: 0;
    }
    
  }
  
}


/* ------ .page-header-home ------ */


.page-header-home {
  
  h1 {
    position: relative;
    font-size: 35px;
    text-align: center;
    padding: 20px 0 !important;
  }
  
  h1:after {
    content: "";
    display: inline-block;
    height: 3px;
    width: 60px;
    background-color: #5a2f23;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 0;
  }
  
}


/* ------ #services-home ------ */


#services-home {
  
  .page-list-ext {
    display: table;
    width: 100%;
    text-align: center;
  }
  
  .page-list-ext-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 240px;
    min-width: 200px;
    min-height: 330px;
    margin: 15px;
    background: #fff;
    border: 1px solid #eee;
  }
  
  .page-list-ext-item h3 {
    display: block;
    min-height: 55px;
    margin-top: 120px;
    margin-bottom: 0;
    padding: 0 10px;
    font-size: 20px;
  }
  
  .page-list-ext-item h3 a {
    text-decoration: none;
  }
  
  .page-list-ext-item-content {
    padding: 0 10px;
    font-size: 0.85rem;
  }
  
  .page-list-ext-meta {
    position: absolute;
    display: block;
    width: 100px;
    height: 100px;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    background: none;
  }
  
  .page-list-more-link {
    position: absolute;
    display: block;
    width: 105px;
    left: 0;
    right: 0;
    bottom: 15px;
    margin: 0 auto;
    padding: 5px 10px;
    background: #5a2f23;
  }
  
  .page-list-more-link a {
    display: block;
    color: #fff;
  }
  
  .org {
    background: #5a2f23 url('/app/themes/mytheme/dist/images/bg.jpg');
    margin: 75px 0;
    padding: 75px 0;
    outline: 5px solid #5a2f23;
    border-top: 2px solid #fadf84;
    border-bottom: 2px solid #fadf84;
    
    h2 {
      position: relative;
      margin-bottom: 75px;
      font-size: 35px;
      text-align: center;
      color: #fff;
    }
    
    h2:after {
      content: "";
      display: inline-block;
      height: 3px;
      width: 60px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      margin-left: -30px;
      bottom: -35px;
    }
    
    .page-list-ext-item {
      border: none;
    }
    
  }
  
}


/*------ #services-site ------*/


.services-site h3 {
  margin: 0;
  padding: 30px 0;
}
.services-site .page-list-ext {
  display: block;
  text-align: left;
}
.services-site .page-list-ext-item {
  position: relative;
  width: 100%;
  min-height: 50px;
  line-height: 50px;
  margin: 10px 0 0 0;
}
.services-site .page-list-ext-item h3 {
  padding: 3px 0 3px 60px;
  font-size: 15px;
}
.services-site .page-list-ext-item h3 a {
  text-decoration: none;
}
.services-site .page-list-ext-meta {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  background: none;
}
.services-site .page-list-ext-meta img {
  width: 50px;
  height: 50px;
}



/* #last-news */


#last-news {
  
  width: 100%;
  background: #fff;
  margin: 75px 0 75px 0;
  
  .last-news-item {
    margin-bottom: 25px;
  }
  
  a.more-link {
    display: inline-block;
    padding: 5px 10px;
    background: #5a2f23;
    color: #fff;
  }
  
}



/* #map */


#map {
  width: 100%;
  height: 300px;
}

/* footer */

footer.content-info {
  
  width: 100%;
  min-height: 76px;
  margin-top: 5px;
  padding: 25px 0;
  background: #5a2f23 url('/app/themes/mytheme/dist/images/bg.jpg');
  outline: 5px solid #5a2f23;
  border-top: 2px solid #fadf84;
  text-align: center;
  color: #fadf84;
  
  p {
    margin-bottom: 0px;
  }
  
}

@media (min-width: 992px) {
  .hidden-lg {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .hidden-md {
    display: none;
  }
}
@media (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 992px) {
  .content main {
    img {
      display: block;
      clear: both;
    }
  }
}


