@font-face {
  font-family: 'Roboto-Regular';
  src: url('/app/themes/mytheme/dist/fonts/Roboto-Regular.eot');
  src: url('/app/themes/mytheme/dist/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Regular.woff2') format('woff2'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Regular.woff') format('woff'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Regular.ttf') format('truetype'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('/app/themes/mytheme/dist/fonts/Roboto-Bold.eot');
  src: url('/app/themes/mytheme/dist/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Bold.woff2') format('woff2'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Bold.woff') format('woff'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Bold.ttf') format('truetype'),
  url('/app/themes/mytheme/dist/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face 
{
  font-family: 'Rex-Bold';
  src: url('/app/themes/mytheme/dist/fonts/rexbold.eot');
  src: url('/app/themes/mytheme/dist/fonts/rexbold.eot?#iefix') format('embedded-opentype'),
  url('/app/themes/mytheme/dist/fonts/rexbold.woff2') format('woff2'),
  url('/app/themes/mytheme/dist/fonts/rexbold.woff') format('woff'),
  url('/app/themes/mytheme/dist/fonts/rexbold.ttf') format('truetype'),
  url('/app/themes/mytheme/dist/fonts/rexbold.svg#Rex-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
